@import "variables.scss";

$app-theme-secondary-key-color: $app-color-warning;
$app-theme-secondary-key-color-highlight: $app-color-danger;
$app-theme-secondary-key-color-visible: $app-highlight-text-color;

.app-layout.secondary-theme {
  .app-sidebar-wrapper {
    .app-sidebar-logo {
      color: $app-theme-secondary-key-color;

      .logo {
        color: $app-theme-secondary-key-color;
      }
    }

    .app-sidebar-nav {
      .navi {
        .nav.highlight .btn {
          background-color: $app-theme-secondary-key-color;
        }
      }
    }
  }

  .app-header {
    .app-header-wrapper {
      background-color: $app-theme-secondary-key-color;

      .nav,
      .nav .al-field,
      .nav button .material-icons {
        color: $app-theme-secondary-key-color-visible;
      }

      .nav button .material-icons .badge {
        background-color: $app-theme-secondary-key-color-highlight;
      }
    }
  }

  .al-button {
    &.filled {
      &.primary {
        @include rippleEffect($app-color-warning);
      }
    }
  }

  .primary {
    border-color: $app-color-warning !important;
    color: $app-color-warning !important;

    &.circled,
    &.rounded,
    &.filled {
      background-color: $app-color-warning !important;
      color: $app-header-color-highlight !important;
    }
  }
}
